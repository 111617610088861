import intl from 'react-intl-universal'
import Session from 'utils/user-storage'

export const getActionLabel = (type) =>
  (type === 'IdSugestao' ? intl.get('stocks.suggestionsListItem.saveSuggestion') : intl.get('stocks.timeline.editOrder'))
export const getActionLabel2 = (type) =>
  (type === 'IdSugestao' ? intl.get('stocks.suggestionsListItem.deleteSuggestion') : intl.get('stocks.suggestionsListItem.deleteOrder'))
export const showTransitTimeDateWarn = () => (<label>{intl.get('stocks.suggestionsListItem.transitTime')}</label >)
export const onlyNumber = (value) => value.replace(/\D/g, '')
export const formatName = (name) => name.split("-")[0]
export const normalizeProductionLine = (data) => {
  return data ? data.split(';').map((value, index) => ({
    key: index,
    productionLine: value
  })) : []
}

export const getItemModel = () => ({
  IdMaterial: '',
  Material: {},
  Materiais: [],
  DocumentTypes: [],
  Fornecedor: {
    QuantidadeLote: '',
    TransitTime: 0
  },
  IdFornecedor: '',
  Coleta: '',
  DataEntregaDe: '',
  ResponsavelEntrega: 2,
  ShowTransitTimeDateWarn: '',
  HoraEntrega: '',
  QuantidadeLote: '',
  TransitTime: 0,
  Quantidade: '',
  QuantidadeCarros: '',
  Total: '',
  PortaWMS: null,
  LinhaDeProducao: '',
  Comentarios: '',
  Observacao: '',
  QuantidadeSugeridaDe: '',
  QuantidadeSugeridaAte: '',
  IsSupplierDisable: true,
  IsOthersDisable: true,
  RequirementCode: null
})

export const getItemModelForUpdate = (data, prevState) => ({
  ...prevState,
  ...data,
  Material: {
    Id: data.IdMaterial,
    Description: data.DescricaoMaterial
  },
  Fornecedor: {
    Id: data.IdFornecedor,
    Description: data.DescricaoFornecedor,
    NomeFornecedor: data.DescricaoFornecedor,
    TransitTime: 1,
    Quantidade: parseFloat(parseFloat(data.Quantidade).toFixed(3)),
    QuantidadeCarros: data.QuantidadeCarros,
    QuantidadeLote: data.QuantidadeLote,
    QuebraEntrega: data.QuebraEntrega,
    Arredondamento: data.QuantidadeLote,
    TaxCode: data.CodigoImposto,
    BuyerGroup: data.GrupoCompradores,
    Organization: data.OrganizacaoCompras
  },
  Coleta: data.DataColeta,
  DataEntregaDe: data.Entrega_Ate,
  LinhaDeProducao: {
    key: 99999,
    productionLine: data.LinhaDeProducao
  },
  RequirementCode: data.RequirementCode
})


export const validateFieldsForSuggestion = (fields, showSto) => {

  if (showSto && (!fields.GrupoCompradores || !fields.OrganizacaoCompras)) {
    return intl.get('stocks.timeline.feedback.transferInformationMandatory')
  }

  if (!fields.DataColeta || !fields.EntregaDe) {
    return intl.get('stocks.timeline.feedback.attentionDeadlinesMustBeMet')
  }

  if (!fields.QuantidadeSugeridaDe || fields.QuantidadeSugeridaDe === 0) {
    return intl.get('stocks.timeline.feedback.attentionQuantityMustBeGreaterThanZero')
  }
}


export const getItemModelForSave = (data) => {
  let model = {}

  model = {
    ...data,
    IdMaterial: data.Material.Id,
    IdFornecedor: data.Fornecedor.Id,
    DataColeta: data.Coleta,
    EntregaDe: data.DataEntregaDe,
    EntregaAte: data.DataEntregaDe,
    LinhaDeProducao: data.LinhaDeProducao && data.LinhaDeProducao.productionLine,
    QuantidadeSugeridaDe: data.Total,
    QuantidadeSugeridaAte: data.Total,
    TotalPallets: data.Quantidade,
    PortaWMS: data.PortaWMS === 'N/A' || data.PortaWMS === intl.get('commons.undefined') ? null : data.PortaWMS,
    CodigoImposto: data.Fornecedor.TaxCode,
    GrupoCompradores: data.Fornecedor.BuyerGroup,
    OrganizacaoCompras: data.Fornecedor.Organization,
    RequirementCode: data.RequirementCode
  }

  return model
}

export const getItemModelForEdit = (data, isSuggestion) => {
  let model = {}

  if (isSuggestion) {
    model = {
      ...data,
      IdSugestao: data.Id,
      IdFornecedor: data.Fornecedor.Id,
      IdMaterial: data.Material.Id,
      DataColeta: data.Coleta,
      EntregaDe: data.DataEntregaDe,
      EntregaAte: data.DataEntregaDe,
      LinhaDeProducao: data.LinhaDeProducao && data.LinhaDeProducao.productionLine,
      QuantidadeSugeridaDe: data.Total,
      QuantidadeSugeridaAte: data.Total,
      TotalPallets: data.Quantidade,
      PortaWMS: data.PortaWMS === 'N/A' || data.PortaWMS === intl.get('commons.undefined') ? null : data.PortaWMS,
      CodigoImposto: data.Fornecedor.TaxCode,
      GrupoCompradores: data.Fornecedor.BuyerGroup,
      OrganizacaoCompras: data.Fornecedor.Organization,
      RequirementCode: data.RequirementCode
    }


    delete model['Id']

  } else {
    model = {
      ...data,
      TotalPallets: data.Quantidade,
      IdPedido: data.Id,
      IdFornecedor: data.Fornecedor.Id,
      IdUsuario: Session.get().Id,
      DataEntrega: data.DataEntregaDe,
      DataColeta: data.Coleta,
      Quantidade: data.Total,
      HoraEntrega: data.HoraEntrega,
      Observacao: data.Observacao,
      IdMaterial: data.Material.Id,
      PortaWMS: data.PortaWMS === 'N/A' || data.PortaWMS === intl.get('commons.undefined') ? null : data.PortaWMS,
      RequirementCode: data.RequirementCode
    }
  }
  return model
}