import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import Card from './../stocks/components/cards/card';
import ModalGradeExcedida from './../../components/modal-grade-excedida';
import ModalPedidoEmOver from './components/modal-pedido-em-over';
import { Textarea, Grid } from '@hbsis.uikit/react';
import { Button } from 'components/uikit-adapter';
import Timeline from 'components/timeline';
import DangerDialog from 'components/danger-dialog';
import Header from 'components/header';
import Loading from 'components/center-loading';
import StatusStock from 'components/status-stock';
import Suggestions from 'components/suggestions-list';
import ModalShelfLife from 'components/modal-shelf-life';
import ManualSuggestionForm from 'components/manual-suggestion-order-form';
import InformAdvanceDelayOrder from 'components/inform-advance-delay-order';
import ChartStocks from 'components/chart-stocks';
import {
  GeneralIcon,
  IconAtualizaGrafico,
  IconCalendarSchedule,
  HourglassIcon
} from 'components/icons/icn-index.icon';
import SessionUser from 'utils/user-storage';
import StorePersist from 'utils/store-persist';
import { formatErrorMessage } from 'utils/handle-error';
import SnackBar from 'material-ui/Snackbar';
import intl from 'react-intl-universal';
import {
  searchTransfers,
  searchVariableStockPolicy,
  searchCardDetail,
  checkSuggestionInconsistencies,
  searchJustificationsRequestOnOver,
  acceptSuggestions,
  acceptExternalSuggestions,
  changeRequest,
  saveSuggestion,
  deleteSuggestion,
  cancelBalance,
  newManualSuggestion,
  cancelOrder,
  applyProgrammingInStock,
  saveOverviewStockInformation,
  createSuggestionByShelfLife,
  composicaoCargaCancel,
  fetchEstoqueCompartilhado,
  fetchExternalWarehouses,
  requestOrderCancellation,
  getMaterialSupplierSettings
} from './stocks-detail.service';
import CompositionOrders from 'components/compositon-orders';
import './stocks-detail.css';
import EstoqueCompartilhado from './components/estoque-compartilhado';
import EstoqueATG from './components/estoque-atg';
import { ObterLinhaProducaoPorCentro } from '../master-data/unit-management/business-unit/business-unit.service';
import Feature from 'components/feature';
import { getValueForCondition, validatesArrayHasValue, generateQueryParams } from 'utils/custom-functions';
import { getDefaultDocumentType, mapperOrdersList, mapperSuggestionsList } from './utils';
import { calculatePalletsQuantityByTotal, calculateQuantityPerTruck } from '../../utils/calculations';
import { getDocumentTypesByBusinessUnityCountry } from 'utils/document-types';
import { formatNumber } from 'utils/format';
import { isLoadCompositionFlowBrewerySide, showInboundTransfer, usesReturnableAssets } from 'utils/validations-general-settings';
import { getDoorsByBusinessUnity } from 'services/doors.service';
import { parseFloatByLocale } from 'locales/utils';
import { ExternalWarehouseBreweryCharts } from './components/external-warehouse-brewery-charts';
import TipoUsuario from 'models/usuarios/tipo-usuario';
import { NoAccessSupplierPage } from 'components/no-access-suplier-page';
import { getValidationForInTransitStatus } from 'features/orders/components/orders-change-date/utils';
import { getTextAreaStyle } from 'components/suggestions-list/suggestions-list-item/suggestions-list-item.styled';
import { generateSuggestions, runStockProjection, updateStockFromSap } from 'services/labeled-family-center.service';
import { RoundButton } from 'components/round-button';
import { getSupplierProductionPlanItem } from "services/supplier-production-plan.service";
import ProductionPlan from './components/production-plan';

const { Row, Col } = Grid
const TipoPerfilPcpUnidade = 4;
const TipoPedidoTransferencia = 2;
class StocksDetail extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        familyId: PropTypes.string,
        itemId: PropTypes.string,
        typeNeed: PropTypes.string
      })
    }),
    history: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      itemsChecked: false,
      itemsShelfLifeChecked: false,
      formValid: true,
      estoqueDetalhe: null,
      lastOrderOpen: {
        index: {},
        tableListBodyElement: {},
      },
      informacoesDoEstoque: {
        UltimaAtualizacao: ''
      },
      pedido: {},
      showFeedback: false,
      messageFeedback: '',
      maxHeightTimeLine: 'initial',
      arrowPosition: '0px',
      requestCount: 0,
      shelflifeExpandedList: [],
      suggestionsExpandedList: [],
      pedidosSelecionados: [],
      itemExpanded: null,
      idFamiliaRotuladaCentro: parseInt(props.match.params.familyId),
      idItemDestacado: props.match.params.itemId,
      tipo: props.match.params.typeNeed, //S - Sugestao P - Pedido
      idPedido: null,
      isPedidoVolume: false,
      idPedidoCancelamento: null,
      isPoliticaEstoqueVariavel: false,
      showCancelConfirm: false,
      showCompositionOrders: false,
      showFormOrder: false,
      justificationMessages: [],
      listaJustificativasSugestaoManual: [],
      listaJustificativasPedidoEmOver: [],
      searchTimeLine: false,
      visualizarGestaoIdade: false,
      existeTransferenciaShelflife: false,
      showModalAlertaEstouroDaGrade: false,
      showModalPedidoEmOver: false,
      estouroDeGradeAceito: false,
      pedidoEmOverAceito: false,
      justificativasPedidoEmOverOption: null,
      idJustificativaCancelamento: null,
      estoqueCompartilhado: null,
      externalWarehouses: null,
      statusPedidoEmCancelamento: null,
      dadosXadrez: [],
      isNewFlow: isLoadCompositionFlowBrewerySide(),
      linhaProducaoCentro: [],
      documentTypes: [],
      doors: [],
      secondChartInfo: {},
      wasUpdateGraph: false,
      updateGraphic: false,
      productionPlan: []
    }

    this.headerHeight = 125
    this.cardDetailElement = null
    this.chartElement = null
    this.arrowElement = null
    this.itemsCheckedData = []
    this.chartStockRef = React.createRef();
  }

  promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

  async loadChart() {
    if (this.chartStockRef.current) {
      await this.chartStockRef.current.loadChart()
    }
  }

  async componentDidMount() {
    this.loadCompletePage();
  }

  perfilPcpUnidadeLogado = () => SessionUser.get().PerfilAcesso === TipoPerfilPcpUnidade;
  async loadLinhaProducaoPorCentro() {
    try {
      const centro = this.state.estoqueDetalhe.Centro
      const data = await ObterLinhaProducaoPorCentro(centro)
      const normalizeData = data ? data.ProductionLine.split(';').map((value, index) => ({
        key: index,
        productionLine: value
      })) : []

      this.setState({
        linhaProducaoCentro: normalizeData
      })
    }
    catch (e) {
      console.log(e)
    }
  }
  
  async loadProductionPlan() {
    try {
      let idUnidadeNegocio = this.state.estoqueDetalhe.IdUnidadeNegocio;
      let idMaterial = this.state.estoqueDetalhe.IdMaterialAtivo;
      let _query = generateQueryParams({ "Filters.MaterialId": idMaterial, "Filters.BusinessUnitId": idUnidadeNegocio });
      const data = await getSupplierProductionPlanItem(_query);

      this.setState({
        productionPlan: data
      })
    }
    catch (e) {
      console.log(e)
    }
  }

  async loadCompletePage() {
    try {
      this.setState({
        searchTimeLine: true,
      })

      await this.getEstoqueCompartilhado();
      await this.getExternalWarehouses();

      if (!this.state.idItemDestacado) {
        await this.carregarPedidoPrincipal()
      }

      let listaSugestoesSemData = [];

      if (this.state.estoqueDetalhe) {
        if (this.state.estoqueDetalhe.ListaSugestoes) {
          listaSugestoesSemData = this.state.estoqueDetalhe.ListaSugestoes.filter(s => !s.DataEntregaAte);
        }
      }
      listaSugestoesSemData.map((sugestao) => (
        this.changeValueSugestao('itemsExpanded', true, sugestao)
      ))

      const [existeTransferenciaShelflife, informacoesDoEstoque] = await Promise.all([
        await this.consultarTransferencias(),
        await this.consultarInformacoesDoEstoqueDoEstoque(),
        await this.carregarDadosDoCard(),
      ])

      if (this.state.estoqueDetalhe.ExternalSuggestions) {
        await this.carregarDadosXadrez()
      }

      if (this.state.estoqueDetalhe) {
        await this.loadProductionPlan();
      }

      this.setState({
        existeTransferenciaShelflife,
        informacoesDoEstoque,
        idPedido: null,
        itemExpanded: null
      })

      await this.loadChart()
    } catch (err) {
      console.log(err)
    }
  }

  async carregarDadosXadrez() {
    const { idFamiliaRotuladaCentro } = this.state
    const data = await getMaterialSupplierSettings(idFamiliaRotuladaCentro)
    this.setState({ dadosXadrez: data })
  }

  async getEstoqueCompartilhado() {
    const { idFamiliaRotuladaCentro } = this.state
    const data = await fetchEstoqueCompartilhado(idFamiliaRotuladaCentro)
    this.setState({
      estoqueCompartilhado: data
    });
  }

  async getExternalWarehouses() {
    const { idFamiliaRotuladaCentro } = this.state
    const data = await fetchExternalWarehouses(idFamiliaRotuladaCentro)
    this.setState({
      externalWarehouses: data
    });
  }

  async carregarPedidoPrincipal() {
    const pedidoPrincipal = await this.carregarDadosTimeline()

    this.setState({
      idPedido: pedidoPrincipal ? pedidoPrincipal.Id : null,
      idItemDestacado: pedidoPrincipal ? pedidoPrincipal.Id : null,
      tipo: pedidoPrincipal ? pedidoPrincipal.Tipo : ''
    })
  }

  async consultarInformacoesDoEstoqueDoEstoque() {
    return {
      UltimaAtualizacao: "",
    };
  }

  async consultarTransferencias() {
    const { idFamiliaRotuladaCentro } = this.state
    this.handleFetch()
    try {
      const data = await searchTransfers(idFamiliaRotuladaCentro)
      return data
    } catch (err) {
      console.log(err)
    } finally {
      this.handleFetch(false)
    }
  }

  async loadDocumentTypes(id) {
    try {
      const documentTypesBusinessUnity = await getDocumentTypesByBusinessUnityCountry(id);
      this.setState({
        documentTypes: documentTypesBusinessUnity,
      })
    } catch (error) {
      console.error(error);
    }
  }

  async loadDoors(id) {
    try {
      const doorsByBusinessUnity = await getDoorsByBusinessUnity(id);
      const undeterminedOption = {
        Id: 0,
        Name: intl.get('commons.undefined')
      }

      if (doorsByBusinessUnity?.length > 0) doorsByBusinessUnity.unshift(undeterminedOption);

      this.setState({
        doors: doorsByBusinessUnity,
      })
    } catch (error) {
      console.error(error);
    }
  }

  async carregarDadosDoCard() {
    const { idFamiliaRotuladaCentro } = this.state
    this.handleFetch()
    try {
      const estoqueDetalhe = await this.loadCardDetail(idFamiliaRotuladaCentro)
      this.loadDocumentTypes(estoqueDetalhe.IdUnidadeNegocio);
      await this.loadDoors(estoqueDetalhe.IdUnidadeNegocio);
      await this.promisedSetState({ estoqueDetalhe });
      this.calcTimelineMaxHeight();
    } catch (err) {
      this.showFeedback(formatErrorMessage(err))
    } finally {
      this.searchMessages()
      this.obterListaJustificativasSugestaoManual()
      this.obterPoliticaEstoqueVariavel()
      this.handleFetch(false)
      this.loadLinhaProducaoPorCentro()
    }
  }

  carregarDadosTimeline = () => new Promise((resolve, reject) => {
    try {
      let pedidoPrincipal;
      if (this.state.estoqueDetalhe.ListaPedidos.length > 0) {
        pedidoPrincipal = {
          Id: this.state.estoqueDetalhe.ListaPedidos[0].Id,
          Tipo: 'P'
        }
      } else if (this.state.estoqueDetalhe.ListaPedidosVolume.length > 0) {
        pedidoPrincipal = {
          Id: this.state.estoqueDetalhe.ListaPedidosVolume[0].Id,
          Tipo: 'V'
        }
      }
      resolve(pedidoPrincipal)
    } catch (err) {
      resolve(err)
    }
  })

  async obterPoliticaEstoqueVariavel() {
    const { idFamiliaRotuladaCentro } = this.state

    this.handleFetch()
    try {
      const data = await searchVariableStockPolicy(idFamiliaRotuladaCentro)
      this.setState({
        isPoliticaEstoqueVariavel: data,
      })
    } catch (err) {
      console.log(err)
    } finally {
      this.handleFetch(false)
    }
  }

  async obterListaJustificativasSugestaoManual() {
    this.handleFetch()
    try {
      const data = JSON.parse(localStorage.getItem('enumerators'));
      this.setState({
        listaJustificativasSugestaoManual: data.SuggestionJustifications,
      })
    } catch (err) {
      this.showFeedback(formatErrorMessage(err))
    } finally {
      this.handleFetch(false)
    }
  }

  async loadCardDetail(idFamiliaRotulada) {
    try {
      const data = await searchCardDetail(idFamiliaRotulada)
      return data
    } catch (err) {
      console.log(err)
    }
  }

  verificarInconsistenciasDaSugestao = async(materials) => {
    const user = SessionUser.get();
    this.setState({
      materials: materials,
      showModalAlertaEstouroDaGrade: false,
      estouroDeGradeAceito: false,
      pedidoEmOverAceito: false,
      gradeData: []
    });

    if (!materials.length) {
      this.showFeedback(intl.get('stocks.timeline.feedback.attentionNoSuggestions'))
    } else {
      this.handleFetch()
      try {
        const { idFamiliaRotuladaCentro } = this.state;
        const data = await checkSuggestionInconsistencies(user.Id, idFamiliaRotuladaCentro, materials)
        this.setParamsPedidoEmOver()

        this.setState({
          semGradeDisponivel: data.disponibilidadeDeGrade.length > 0,
          sugestaoGeraEstoqueOver: data.sugestaoGeraEstoqueOver,
          sugestoesUltrapassamEstoqueMaximo: data.sugestoesUltrapassamEstoqueMaximo,
          retornoInconsistenciasDaSugestao: data
        }, () => { this.requisitosParaAceiteDeSugestao() })
      } catch (err) {
        this.showFeedback(err.response.Message)
      } finally {
        this.handleFetch(false)
      }
    }
  }

  setParamsPedidoEmOver = () => {
    this.setState({
      estouroDeGradeAceito: false,
      justificativasPedidoEmOverOption: null
    })
  }

  requisitosParaAceiteDeSugestao = () => {
    if (this.state.semGradeDisponivel && !this.state.estouroDeGradeAceito) {
      this.processDataDisponibilidadeDeGrade()
      return
    }
    if ((this.state.sugestaoGeraEstoqueOver || this.state.sugestoesUltrapassamEstoqueMaximo) && !this.state.pedidoEmOverAceito) {
      this.processDataPedidoEmOver()
      return
    }

    if (((this.state.semGradeDisponivel && this.state.estouroDeGradeAceito)
      || ((this.state.sugestaoGeraEstoqueOver || this.state.sugestoesUltrapassamEstoqueMaximo) && this.state.pedidoEmOverAceito))
      || !(this.state.sugestaoGeraEstoqueOver || this.state.sugestoesUltrapassamEstoqueMaximo) || !this.state.semGradeDisponivel)
      this.aceitarSugestoes()
  }

  estouroDeGradeAceito = () => {
    this.handleShowModalEstouroDaGrade()
    this.setState({
      estouroDeGradeAceito: true
    }, () => this.requisitosParaAceiteDeSugestao())
  }

  pedidoEmOverAceito = (justificativa) => {
    if (!justificativa.JustificativasPedidoEmOverOption) {
      this.showFeedback(intl.get('stocks.timeline.feedback.enterJustificationOrderingOver'))
    }
    else {
      this.setState(() => ({
        pedidoEmOverAceito: true,
        justificativasPedidoEmOverOption: justificativa.JustificativasPedidoEmOverOption
      }), () => {
        this.handleShowModalPedidoEmOver()
        this.requisitosParaAceiteDeSugestao()
      })
    }
  }

  handleShowModalPedidoEmOver = () => {
    this.setState(prevState => ({
      showModalPedidoEmOver: !prevState.showModalPedidoEmOver
    }))
  }

  processDataDisponibilidadeDeGrade = () => {
    this.setState(prevState => ({
      gradeData: prevState.retornoInconsistenciasDaSugestao.disponibilidadeDeGrade
    }), () => this.handleShowModalEstouroDaGrade())
  }

  async processDataPedidoEmOver() {
    this.handleFetch()

    try {
      const data = await searchJustificationsRequestOnOver();

      this.setState(() => ({
        listaJustificativasPedidoEmOver: data
      }), () => {
        this.handleShowModalPedidoEmOver()
      })
    } catch (err) {
      this.showFeedback(`Erro: ${err.message}`)
      console.log(err);
    } finally {
      this.handleFetch(false)
    }
  }

  async aceitarSugestoes() {
    let sugestaoGeraEstoqueOverOuMaxmimaEstoque = (this.state.sugestaoGeraEstoqueOver || this.state.sugestoesUltrapassamEstoqueMaximo)
    let justificativasPedidoEmOverOption = this.state.justificativasPedidoEmOverOption
    let materials = this.state.materials
    const user = SessionUser.get()
    if (!materials.length) {
      this.showFeedback(intl.get('stocks.timeline.feedback.attentionNoSuggestions'))
    } else {
      this.handleFetch()
      try {
        const isExternalSuggestion = this.state.estoqueDetalhe.ExternalSuggestions
        if (isExternalSuggestion) {
          await acceptExternalSuggestions(user.Id, this.state.idFamiliaRotuladaCentro, justificativasPedidoEmOverOption, sugestaoGeraEstoqueOverOuMaxmimaEstoque, materials)
        }
        else {
          await acceptSuggestions(user.Id, this.state.idFamiliaRotuladaCentro, justificativasPedidoEmOverOption, sugestaoGeraEstoqueOverOuMaxmimaEstoque, materials);
        }
        this.showFeedback(intl.get('stocks.timeline.feedback.successfullyAcceptedSuggestions'))
      } catch (err) {
        this.showFeedback(formatErrorMessage(err))
      } finally {
        await runStockProjection(this.state.idFamiliaRotuladaCentro);
        this.setState(prevState => ({ updateGraphic: !prevState.updateGraphic }))
        this.loadCompletePage()
        this.handleFetch(false)
      }
    }
  }

  async saveOrder(item) {
    const alteracaoPedido = {
      IdPedido: item.Id,
      ResponsavelEntrega: item.ResponsavelEntrega,
      HoraEntrega: item.HoraEntrega,
      Total: item.Total,
      RequirementCode: item.RequirementCode
    };

    this.handleFetch();
    try {
      const data = await changeRequest(alteracaoPedido);
      if (data) {
        this.showFeedback(intl.get('orders.feedback.orderSavedSuccessfully'))
        this.loadCompletePage();
      }
      else
        this.showFeedback(intl.get('stocks.timeline.feedback.noChangeOrder'))
    } catch (err) {
      this.showFeedback(formatErrorMessage(err))
      console.log(err)
    } finally {
      this.handleFetch(false);
    }
  }

  salvarSugestao = (itemSugestao) => {
    const sugestao = {
      Id: itemSugestao.Id,
      IdMaterial: itemSugestao.IdMaterial,
      IdFornecedor: itemSugestao.IdFornecedor,
      PortOfLoadingId: itemSugestao.PortOfLoadingId,
      PortOfDischargeId: itemSugestao.PortOfDischargeId,
      EntregaDe: itemSugestao.DataEntregaDe,
      EntregaAte: itemSugestao.DataEntregaDe,
      QuantidadeSugeridaDe: parseFloatByLocale(itemSugestao.Total),
      QuantidadeSugeridaAte: parseFloatByLocale(itemSugestao.Total),
      UnidadeMedidaAcordada: itemSugestao.UnidadeMedidaAcordada,
      Observacao: itemSugestao.Observacao,
      DataColeta: itemSugestao.Coleta,
      CodigoImposto: itemSugestao.CodigoImposto,
      GrupoCompradores: itemSugestao.GrupoCompradores,
      OrganizacaoCompras: itemSugestao.OrganizacaoCompras,
      TipoJustificativaSugestaoManual: itemSugestao.TipoJustificativaSugestaoManual,
      ResponsavelEntrega: itemSugestao.ResponsavelEntrega,
      PrioridadeXadrez: itemSugestao.PrioridadeXadrez,
      HoraEntrega: itemSugestao.HoraEntrega,
      ClasseSAP: itemSugestao.ClasseSAP,
      Comentarios: itemSugestao.Comentarios,
      LinhaDeProducao: itemSugestao.LinhaDeProducao,
      DocumentType: itemSugestao.DocumentType,
      PortaWMS: itemSugestao.PortaWMS === 'N/A' || itemSugestao.PortaWMS === intl.get('commons.undefined') ? null : itemSugestao.PortaWMS,
      RequirementCode: itemSugestao.RequirementCode
    };

    this.validateSaveSuggestions(sugestao, itemSugestao);
  }

  validateSaveSuggestions = async(sugestao, itemSugestao) => {
    const dataAtual = moment().format("YYYY-MM-DD");
    const dataColeta = moment(sugestao.DataColeta).format("YYYY-MM-DD");
    const dataEntrega = moment(sugestao.EntregaDe).format("YYYY-MM-DD");
    const isExternalSuggestion = this.state.estoqueDetalhe.ExternalSuggestions

    if (!sugestao.DataColeta || !sugestao.EntregaDe) {
      this.showFeedback(intl.get('stocks.timeline.feedback.attentionDeadlinesMustBeMet'))
    } else if (!sugestao.IdFornecedor) {
      this.showFeedback(intl.get('stocks.timeline.feedback.attentionSupplierNotFilled'))
    } else if (dataColeta < dataAtual || dataEntrega < dataAtual) {
      this.showFeedback(intl.get('stocks.timeline.feedback.noDateBeforeCurrentDayAllowed'))
    } else if (dataColeta > dataEntrega) {
      this.showFeedback(intl.get('stocks.timeline.feedback.collectionDateMustBeLessThanDeliveryDate'))
    } else if (!sugestao.QuantidadeSugeridaDe) {
      this.showFeedback(intl.get('stocks.timeline.feedback.attentionQuantityMustBeGreaterThanZero'))
    } else if (this.state.estoqueDetalhe.IsHourlyPlan && !sugestao.HoraEntrega) {
      this.showFeedback(intl.get('stocks.timeline.feedback.attentionDeliveryTimeMustBeMet'))
    } else {
      this.handleFetch();
      try {
        await saveSuggestion(
          {
            ...sugestao,
            Id: itemSugestao.Id,
            IdUnidadeNegocio: this.state.estoqueDetalhe.IdUnidadeNegocio
          },
          isExternalSuggestion);

        this.showFeedback(intl.get('stocks.timeline.feedback.suggestionSavedSuccessfully'));
        this.changeValueSugestao('naoSalvo', false, itemSugestao);
      } catch (err) {
        this.showFeedback(formatErrorMessage(err))
      } finally {
        this.setState(prevState => ({ updateGraphic: !prevState.updateChart }))
        this.loadCompletePage();
        this.handleFetch(false);
      }
    }
  }

  excluirSugestaoLista(index) {
    const listaSugestoes = this.state.estoqueDetalhe.ListaSugestoes
    const lista = listaSugestoes.filter((item) => item.SuggestionIndex !== index)
    this.setState(prevState => ({ estoqueDetalhe: { ...prevState.estoqueDetalhe, ListaSugestoes: lista } }))
  }

  async excluirSugestao(sugestoes) {
    if (!Array.isArray(sugestoes)) {
      if (!sugestoes.Id) {
        this.excluirSugestaoLista(sugestoes.SuggestionIndex)
        return
      }
      sugestoes = [sugestoes.Id]
    }
    this.handleFetch();
    try {
      const { estoqueDetalhe } = this.state;
      const isExternalSuggestion = estoqueDetalhe.ExternalSuggestions

      await deleteSuggestion(this.state.idFamiliaRotuladaCentro, sugestoes, isExternalSuggestion)
      this.showFeedback(intl.get('stocks.timeline.feedback.suggestionDeletedSuccessfully'));
      let listaSugestoes = estoqueDetalhe.ListaSugestoes;
      sugestoes.forEach(sugestao => {
        const indexSugestao = listaSugestoes.findIndex(item => item.Id === sugestao)
        listaSugestoes = [
          ...listaSugestoes.slice(0, indexSugestao),
          ...listaSugestoes.slice(indexSugestao + 1),
        ]
      });
      this.setState(prevState => ({
        estoqueDetalhe: {
          ...prevState.estoqueDetalhe,
          ListaSugestoes: listaSugestoes
        }
      }))
      await this.loadChart()
    } catch (err) {
      this.showFeedback(formatErrorMessage(err))
    } finally {
      this.handleFetch(false);
    }
  }

  async cancelarSaldoPedidoVolume(itemVolume) {
    const user = SessionUser.get();
    this.handleFetch();

    try {
      await cancelBalance(itemVolume.Id, this.state.idFamiliaRotuladaCentro, user.Id);
      this.showFeedback(intl.get('stocks.timeline.feedback.volumeCanceledSuccessfully'));
      await this.loadChart()
      const indexVolume = this.state.estoqueDetalhe.ListaPedidosVolume.findIndex(item => item.Id === itemVolume.Id);
      this.setState(prevState =>({
        estoqueDetalhe: {
          ...prevState.estoqueDetalhe,
          ListaPedidosVolume: [
            ...prevState.estoqueDetalhe.ListaPedidosVolume.slice(0, indexVolume),
            ...prevState.estoqueDetalhe.ListaPedidosVolume.slice(indexVolume + 1),
          ]
        }
      }))
    } catch (err) {
      this.showFeedback(intl.get('stocks.timeline.feedback.thereProblemDeletingVolume'));
    } finally {
      this.handleFetch(false);
    }
  }

  changeValueExternalSuggestion = (xadrez) => {
    const { ListaSugestoes, IdMaterialAtivo } = this.state.estoqueDetalhe
    const indexItem = ListaSugestoes.length === 0 ? 0 : ListaSugestoes.length
    const { Suppliers, MaterialUnitMeasure } = xadrez.find(material => material.Id === IdMaterialAtivo)

    let suggestion = {
      SuggestionIndex: indexItem,
      IdMaterial: IdMaterialAtivo,
      IdFornecedor: Suppliers[0].Id,
      OrigemFornecedor: 1,
      StatusSigla: "SM",
      Status: "Sugestão Manual",
      Tipo: Suppliers[0].SupplyType,
      TipoDescricao: Suppliers[0].SupplyTypeDescription,
      UnidadeMedida: MaterialUnitMeasure,
      Materiais: xadrez.map((material) => ({
        Id: material.Id,
        Fornecedores: material.Suppliers.map(supplier => ({
          Arredondamento: supplier.Rouding,
          Descricao: supplier.Description,
          Id: supplier.Id,
          LoteMinimo: 0,
          PrioridadeXadrez: supplier.Priority,
          QuebraEntrega: supplier.DeliveryBreak,
          ResponsavelEntrega: supplier.DeliveryResponsible,
          TipoFornecimentoDescrição: supplier.SupplyTypeDescription,
          TipoFornecimentoSigla: supplier.SupplyType,
          TipoFornecimentoValue: supplier.SupplyTypeValue,
          TipoUnidade: supplier.UnitType,
          TransitTime: supplier.TransitTime,
          Origem: supplier.IsTransferUnit ? 2 : 1
        })),
        MaterialPesquisa: material.MaterialTitle,
        NomeMaterialCadastro: `${material.MaterialNumber} - `,
        NomeMaterialComCodigoLegado: `${material.MaterialNumber} - `,
        Numero: material.Number
      })),
      itemsExpanded: true,
      itemAlterado: false,
      QuantidadeLote: Suppliers[0].Rouding,
      QuebraEntrega: Suppliers[0].DeliveryBreak,
      ResponsavelEntrega: Suppliers[0].DeliveryResponsible,
      TransitTime: Suppliers[0].TransitTime
    }

    suggestion = this.formatFields('Quantidade', "1", suggestion)

    this.setState(state => ({
      estoqueDetalhe: {
        ...state.estoqueDetalhe,
        ListaSugestoes: [
          ...state.estoqueDetalhe.ListaSugestoes,
          {
            ...suggestion,
          }
        ],
      }
    }), () => this.validateForm())

  }

  createExternalSuggestion() {
    const { dadosXadrez } = this.state
    this.changeValueExternalSuggestion(dadosXadrez)
  }

  async createSuggestionManual() {
    this.handleFetch();

    try {
      const data = await newManualSuggestion(this.state.idFamiliaRotuladaCentro)
      this.setState(prevState => ({
        estoqueDetalhe: {
          ...prevState.estoqueDetalhe,
          ListaSugestoes: [
            ...prevState.estoqueDetalhe.ListaSugestoes,
            data
          ]
        }
      }), () => this.changeValueSugestao('itemsExpanded', true, data))

      this.setState(prevState => ({ updateGraphic: !prevState.updateChart }))

    } catch (err) {
      this.showFeedback(formatErrorMessage(err))
    } finally {
      this.handleFetch(false)
    }
  }

  searchMessages() {
    try {
      const data = JSON.parse(localStorage.getItem('enumerators'));
      this.proccessDataMessages(data.OrderCancellationJustifications)
    } catch (err) {
      this.showFeedback(formatErrorMessage(err))
    }
  }

  proccessDataMessages(data) {
    this.setState({
      justificationMessages: data
    })
  }

  async handleConfirmRequestOrderCancellation(idJustificativaCancelamento, messageUserToSupplier, forcedCancel) {
    const { idPedidoCancelamento, idFamiliaRotuladaCentro } = this.state;
    const indexPedido = this.state.estoqueDetalhe.ListaPedidos.findIndex(item => item.Id === idPedidoCancelamento)
    const user = SessionUser.get()
    this.handleFetch()
    this.closeCancelConfirm()
    try {
      const requestDto = {
        IdPedido: idPedidoCancelamento,
        MessageUser: messageUserToSupplier,
        IdJustificativa: idJustificativaCancelamento,
        IdUsuario: user.Id,
        IdFamiliaRotuladaCentro: idFamiliaRotuladaCentro,
        Pedido: this.state.estoqueDetalhe.ListaPedidos[indexPedido],
        ForcedCancel: forcedCancel
      }
      const orderUpdated = await requestOrderCancellation(requestDto);
      if (requestDto.ForcedCancel) await runStockProjection(requestDto.IdFamiliaRotuladaCentro);
      const newOrderList = [...this.state.estoqueDetalhe.ListaPedidos];
      newOrderList[indexPedido] = orderUpdated
      this.setState(prevState => ({
        itemExpanded: null,
        lastOrderOpen: {
          index: {},
          tableListBodyElement: {},
        },
        pedidosSelecionados: [],
        estoqueDetalhe: {
          ...prevState.estoqueDetalhe,
          ListaPedidos: [
            ...newOrderList
          ]
        },
        idPedido: null
      }))
    }
    catch (e) {
      this.showFeedback(formatErrorMessage(e))
    }
    await this.loadChart()
    this.handleFetch(false)
  }

  async cancelarPedido(idJustificativaCancelamento, messageUserToSupplier, forcedCancel) {
    this.handleFetch()
    const { idPedidoCancelamento, pedido } = this.state;
    if (pedido && pedido.PedidoOriginadoVolume) {
      this.closeCancelConfirm()
      this.showCompositionOrders(idJustificativaCancelamento)
    } else {
      const user = SessionUser.get()
      const cancelamento = {
        IdPedido: idPedidoCancelamento,
        IdJustificativa: idJustificativaCancelamento,
        IdUsuario: user.Id,
        MessageUser: messageUserToSupplier,
        ForcedCancel: forcedCancel
      }

      try {
        await cancelOrder(this.state.idFamiliaRotuladaCentro, cancelamento)
        this.showFeedback(intl.get('stocks.timeline.feedback.orderCanceledSuccessfully'))
        this.closeCancelConfirm()
        const indexPedido = this.state.estoqueDetalhe.ListaPedidos.findIndex(item => item.Id === idPedidoCancelamento)
        this.setState(prevState => ({
          itemExpanded: null,
          lastOrderOpen: {
            index: {},
            tableListBodyElement: {},
          },
          pedidosSelecionados: [],
          estoqueDetalhe: {
            ...prevState.estoqueDetalhe,
            ListaPedidos: [
              ...prevState.estoqueDetalhe.ListaPedidos.slice(0, indexPedido),
              ...prevState.estoqueDetalhe.ListaPedidos.slice(indexPedido + 1),
            ]
          }
        }), () => this.calcTimelineMaxHeight(null, null, null, null, null, null, null))
        await this.loadChart()
      } catch (err) {
        this.showFeedback(formatErrorMessage(err))
      } finally {
        this.handleFetch(false);
      }
    }
  }

  closeCompositionOrders = () => {
    this.setState({ showCompositionOrders: false })
  }

  showCompositionOrders = (idJustificativaCancelamento) => {
    this.setState({ showCompositionOrders: true, idJustificativaCancelamento })
  }

  showCancelConfirm = (item) => {
    this.setState({
      showCancelConfirm: true,
      idPedidoCancelamento: item.Id,
      statusPedidoEmCancelamento: item.IntStatus
    })
  }

  closeCancelConfirm = () => {
    this.setState({ showCancelConfirm: false })
  }

  showFormOrder = (item) => {
    this.setState({
      showFormOrder: true,
      pedido: {
        NumeroPedido: item.Id,
        NumeroPedidoSap: item.CodigoPedidoSAP,
        NumeroItemPedidoSap: item.CodigoItemPedidoSap,
        DesabilitarDataColeta: item.IntStatus == 8,
        DesabilitarQuantidade: getValidationForInTransitStatus(item.IntStatus),
        DataColeta: moment(item.Coleta).format('L'),
        DataEntrega: moment(item.DataEntregaDe).format('L'),
        DataCriacao: item.DataCriacao,
        PossuiComposicao: item.PossuiComposicao,
        TransitTime: item.TransitTime,
        PedidoOriginadoVolume: item.PedidoOriginadoVolume,
        HoraEntrega: item.HoraEntrega,
        Quantidade: item.Total,
        Material: item.Material.MaterialPesquisa,
        UnidadeMedida: item.UnidadeMedida,
        IdFornecedor: item.IdFornecedor
      }
    })
  }

  showEstoqueATG = () => usesReturnableAssets() && this.state.estoqueDetalhe.MaterialIsAtivoGiro

  closeFormOrder = () => { this.setState({ showFormOrder: false, }) }

  salvarNovasDatasColetaEntrega = () => {
    this.setState({
      showFormOrder: false,
    }, () => { this.loadCompletePage() })
  }

  async iniciarProgramacao() {
    const { idFamiliaRotuladaCentro, estoqueDetalhe: { Programado } } = this.state
    this.handleFetch()

    try {
      await applyProgrammingInStock(idFamiliaRotuladaCentro);

      this.setState(state => ({
        estoqueDetalhe: {
          ...state.estoqueDetalhe,
          Programado: !state.estoqueDetalhe.Programado,
        }
      }), () => {
        this.showFeedback(Programado ? intl.get('stocks.timeline.feedback.stockSuccessfullyDeprogrammed') :
          intl.get('stocks.timeline.feedback.stockProgrammedSuccessfully'))
      })
    } catch (err) {
      this.showFeedback(formatErrorMessage(err))
    } finally {
      this.handleFetch(false)
      this.props.history.push("/stocks")
    }
  }

  visualizarGestaoIdade = () => {
    this.setState(prevState => ({
      visualizarGestaoIdade: !prevState.visualizarGestaoIdade,
    }))
  }

  handleCloseReload = () => {
    this.setState(prevState => ({
      visualizarGestaoIdade: !prevState.visualizarGestaoIdade,
    }), () => { this.loadCompletePage() })
  }

  redirectGeneral = () => {
    const params = {
      IdCarteira: null,
      IdFamilia: null,
      IdFamiliaRotulada: this.state.estoqueDetalhe.IdFamiliaRotulada,
      IdCentro: this.state.estoqueDetalhe.IdUnidadeNegocio,
      IdCoordenacao: null,
    }
    StorePersist.setValuesJSON('filtersFamiliaRotuladaCentro', params)
    this.props.history.push(`/master-data/general`)
  }


  mensagemAtualizarGraficoComFlag = (flag) => flag ?
    this.showFeedback(intl.get('stocks.stockDetail.messageStockProjectionGenlots')) : null

  async atualizarGrafico() {
    this.handleFetch()
    const { idFamiliaRotuladaCentro } = this.state
    try {
      await updateStockFromSap({ "IdLabeledFamilyCenter": idFamiliaRotuladaCentro });
      await runStockProjection(idFamiliaRotuladaCentro);
      await generateSuggestions({ "IdLabeledFamilyCenter": idFamiliaRotuladaCentro });
      await this.loadCompletePage()
      this.mensagemAtualizarGraficoComFlag(this.state.estoqueDetalhe.ExternalSuggestions)
      this.setState((prevState) => ({
        wasUpdateGraph: !prevState.wasUpdateGraph
      }))
    } catch (err) {
      this.showFeedback(formatErrorMessage(err))
    } finally {
      this.handleFetch(false)
    }
  }

  usuarioComPermissaoParaCancelarPedidos = (possuiPermissao) => {
    this.setState({
      possuiPermissao
    })
  }

  async cancelarComposicao() {
    const user = SessionUser.get();
    const cancelamento = { OrderId: this.state.pedido.NumeroPedido, JustificationId: this.state.idJustificativaCancelamento, UserId: user.Id, LoadCompositionId: this.state.itemExpanded.IdComposicao };

    try {
      await composicaoCargaCancel(this.state.itemExpanded.IdComposicao, cancelamento);
      this.closeCompositionOrders();
      this.showFeedback(intl.get('stocks.timeline.feedback.orderCanceledSuccessfully'));
      this.loadCompletePage();
    }
    catch (err) {
      this.showFeedback(formatErrorMessage(err))
    }
    finally {
      this.handleFetch(false)
    }
  }

  handlecheckAllItems = (itemsChecked) => {
    this.itemsCheckedData = itemsChecked
    this.validateForm()
  }

  handleCheckItem = (itemsChecked) => {
    this.itemsCheckedData = itemsChecked
    this.validateForm()
  }

  handleCheckShelfLifeItem = (itemsChecked) => {
    this.setState({ itemsShelfLifeCheckedData: itemsChecked })
  }

  renderHeaderCustomOptions = (programado, alerta) => (
    <div className="options-wrapper">
      <RoundButton
        className={cx("icn-btn icn-btn-programar", { alerta })}
        onClick={this.visualizarGestaoIdade}
        title={intl.get("stocks.actions.geralVisionStock")}
        iconHeight='22px'
      >
        <HourglassIcon width='22px' height='22px' color={alerta ? '#cc0e00' : ''} />
      </RoundButton>

      <RoundButton
        className={"icn-btn icn-btn-programar"}
        onClick={this.atualizarGrafico.bind(this)}
        title={intl.get("stocks.actions.updateGraph")}
        iconHeight='24px'
      >
        <IconAtualizaGrafico width='24px' height='24px' />
      </RoundButton>

      <RoundButton
        className={cx("icn-btn icn-btn-programar", { programado })}
        onClick={this.iniciarProgramacao.bind(this)}
        title={`${programado ? intl.get("stocks.actions.unprogramStock") : intl.get("stocks.actions.programStock")}`}
        iconHeight='24px'
      >
        <IconCalendarSchedule width='24px' height='24px' />
      </RoundButton>

      {!this.perfilPcpUnidadeLogado() && (
        <RoundButton
          className="icn-btn icn-btn-programar"
          onClick={this.redirectGeneral}
          title={intl.get("stocks.actions.goToMasterDataGeneral")}
          iconHeight='24px'
        >
          <GeneralIcon width="24px" height="24px" />
        </RoundButton>
      )}
    </div>
  )

  searchTimeLineFinished = () => { this.setState({ searchTimeLine: false, }) }

  showFeedback = (message) => {
    this.setState({
      showFeedback: true,
      messageFeedback: message,
    })
  }

  closeFeedback = () => { this.setState({ showFeedback: false }) }

  validateForm = () => {
    const { estoqueDetalhe: { ListaSugestoes, }, } = this.state
    const invalidItem = ListaSugestoes.find(sugg => Object.keys(sugg).some(item => sugg[item] === ''))
    invalidItem && this.itemsCheckedData.includes(invalidItem.Id)
  }

  changeValue = (event) => {
    const prop = event.currentTarget.name
    const value = event.currentTarget.value

    this.setState(prevState => ({
      estoqueDetalhe: {
        ...prevState.estoqueDetalhe,
        [prop]: value
      }
    }))
  }

  handleFetch = (isFetching = true) => {
    this.setState((prevState) => ({
      requestCount: isFetching ? prevState.requestCount + 1 : prevState.requestCount - 1,
    }))
  }

  formatFields = (name, value, item) => {
    const defaultValue = {
      ...item,
      [name]: value,
    }

    switch (name) {
      case 'IdMaterial': {
        return {
          ...item,
          [name]: value,
        }
      }
      case 'Quantidade': {
        const calculatedAmount = calculateQuantityPerTruck(value, item.QuebraEntrega, item.QuantidadeLote)
        return {
          ...item,
          Quantidade: calculatedAmount.QuantityPallets,
          Total: calculatedAmount.Total,
          QuantidadeCarros: calculatedAmount.TrucksQuantity,
        }
      }
      case 'Total': {
        const _calculatedAmount = calculatePalletsQuantityByTotal(value, item.QuebraEntrega, item.QuantidadeLote)
        return {
          ...item,
          Quantidade: _calculatedAmount.QuantityPallets,
          Total: _calculatedAmount.Total,
          QuantidadeCarros: _calculatedAmount.TrucksQuantity,
        }
      }
      case 'IdFornecedor': {
        const newDefaultDocumentType = getDefaultDocumentType(this.state.documentTypes, item.Materiais, item.IdMaterial, value);
        const blockedDeadlineControl = localStorage.getItem(`blocked_deadlinecontrol-sugestao-${item.Id}`) ?? "true"; 
        const canUpdateDate = blockedDeadlineControl == "true";

        let fornecedor = { Arredondamento: 0, PrioridadeXadrez: 1, QuebraEntrega: 0, ResponsavelEntrega: 2, TransitTime: 2 };
        let _quantityData = { QuantityPallets: item.Quantidade, Total: item.Total, TrucksQuantity: item.QuantidadeCarros };
        let _deliveryDate = item.DataEntregaDe;        

        if (value !== null) {
          const material = item.Materiais.find(m => m.Id === item.IdMaterial)
          fornecedor = material.Fornecedores.find(f => f.Id === value)
        }

        if(value !== null && item.Total) _quantityData = calculatePalletsQuantityByTotal(item.Total, fornecedor.QuebraEntrega, fornecedor.Arredondamento);

        if(value != null && !item.Total && item.Quantidade) _quantityData = calculateQuantityPerTruck(item.Quantidade, fornecedor.QuebraEntrega, fornecedor.Arredondamento);

        if(value !== null && item.Coleta && canUpdateDate) _deliveryDate = moment(item.Coleta).add(Number(fornecedor.TransitTime), 'days').format('YYYY-MM-DD');

        return {
          ...item,
          [name]: value,
          QuantidadeLote: fornecedor?.Arredondamento,
          QuebraEntrega: fornecedor?.QuebraEntrega,
          ResponsavelEntrega: fornecedor?.ResponsavelEntrega,
          TransitTime: fornecedor?.TransitTime,
          PrioridadeXadrez: fornecedor?.PrioridadeXadrez,
          DocumentType: newDefaultDocumentType,
          Quantidade: _quantityData.QuantityPallets,
          QuantidadeCarros: _quantityData.TrucksQuantity,
          DataEntregaDe: _deliveryDate,
          Total: _quantityData.Total,
          PortOfLoadingId: getValueForCondition(fornecedor?.InternationalSupplierGroupId, item.PortOfLoadingId, null),
          PortOfDischargeId: getValueForCondition(fornecedor?.InternationalSupplierGroupId, item.PortOfDischargeId, null)
        }
      }
      default: {
        return defaultValue
      }
    }
  }

  changeValueDeadline = (coleta, entrega, item) => {
    const {
      estoqueDetalhe: {
        ListaSugestoes,
      },
    } = this.state

    const indexItem = ListaSugestoes.findIndex(sugg => sugg.Id === item.Id)
    const suggestion = {
      ...item,
      Coleta: coleta,
      DataEntregaDe: entrega,
      ShowTransitTimeDateWarn: false,
      itemAlterado: true,
      naoSalvo: true,
    }

    this.setState(state => ({
      estoqueDetalhe: {
        ...state.estoqueDetalhe,
        ListaSugestoes: [
          ...ListaSugestoes.slice(0, indexItem),
          {
            ...suggestion,
          },
          ...ListaSugestoes.slice(indexItem + 1),
        ],
      }
    }), () => this.validateForm())
  }


  expandSuggestion = (eventTarget, item, opened, isOrder, isOrderBulk, itemIndex, tableListBodyElement) => {
    this.changeValueSugestao('itemsExpanded', !item.itemsExpanded, item)
  }

  expandShelfLife = (eventTarget, item, opened, isOrder, isOrderBulk, itemIndex, tableListBodyElement) => {
    this.changeShelfLife('itemsShelfLifeExpanded', !item.itemsShelfLifeExpanded, item)
  }

  changeShelfLife = (name, value, item) => {
    const {
      estoqueDetalhe: { ListaLotesShelflife },
    } = this.state

    const indexItem = ListaLotesShelflife.findIndex(sugg => sugg.Id === item.Id)
    const lote = this.formatFields(name, value, item)


    this.setState(state => ({
      estoqueDetalhe: {
        ...state.estoqueDetalhe,
        ListaLotesShelflife: [
          ...ListaLotesShelflife.slice(0, indexItem),
          {
            ...lote,
          },
          ...ListaLotesShelflife.slice(indexItem + 1),
        ],
      }
    }))
  }

  changeHoraEntrega = (name, value, item) => {
    const {
      estoqueDetalhe: { ListaSugestoes },
    } = this.state

    const indexItem = ListaSugestoes.findIndex(s => s.Id === item.Id)

    const sugestao = {
      ...item,
      [name]: value,
    }

    this.setState(state => ({
      estoqueDetalhe: {
        ...state.estoqueDetalhe,
        ListaSugestoes: [
          ...ListaSugestoes.slice(0, indexItem),
          {
            ...sugestao,
          },
          ...ListaSugestoes.slice(indexItem + 1),
        ],
      }
    }), () => this.validateForm())
  }


  changeValueSugestao = (name, value, item) => {
    const { ListaSugestoes } = this.state.estoqueDetalhe
    const indexItem = item.Id ? ListaSugestoes.findIndex(sugg => sugg.Id === item.Id) : item.SuggestionIndex
    const suggestion = this.formatFields(name, value, item)

    if (suggestion.TransitTime != item.TransitTime && suggestion.DataEntregaDe !== item.DataEntregaDe) {
      suggestion.TransitTime = item.TransitTime
      suggestion.ShowTransitTimeDateWarn = true
    } else {
      suggestion.ShowTransitTimeDateWarn = false
    }

    if (name === 'Quantidade' || name === 'Total') {
      suggestion.itemAlterado = true
    }

    suggestion.Tipo = item.Tipo
    suggestion.TipoDescricao = item.TipoDescricao

    if (name !== 'itemsExpanded' && name !== 'naoSalvo') {
      suggestion.naoSalvo = true
    }

    this.setState(state => ({
      estoqueDetalhe: {
        ...state.estoqueDetalhe,
        ListaSugestoes: [
          ...ListaSugestoes.slice(0, indexItem),
          {
            ...suggestion,
          },
          ...ListaSugestoes.slice(indexItem + 1),
        ],
      }
    }), () => this.validateForm())
  }

  changeValueOrder = (name, value, item) => {
    const {
      estoqueDetalhe: { ListaPedidos },
    } = this.state

    const indexItem = ListaPedidos.findIndex(order => order.Id === item.Id)
    const order = this.formatFields(name, value, item);

    this.setState(state => ({
      estoqueDetalhe: {
        ...state.estoqueDetalhe,
        ListaPedidos: [
          ...ListaPedidos.slice(0, indexItem),
          {
            ...order,
          },
          ...ListaPedidos.slice(indexItem + 1),
        ],
      }
    }), () => this.validateForm())
  }

  calcTimelineMaxHeight = (eventTarget, item, opened, isOrder, isOrderBulk, itemIndex, tableListBodyElement) => {
    const { itemExpanded, lastOrderOpen, pedidosSelecionados } = this.state;
    const heightCardDetail = this.cardDetailElement.clientHeight
    const heightChart = this.chartElement.clientHeight
    const isPedidoSelecionado = !!item && opened && isOrder
    const _itemExpanded = getValueForCondition(isOrder, item, itemExpanded)
    const index = getValueForCondition(isOrder, itemIndex, lastOrderOpen.index)

    let listBodyElement = lastOrderOpen.tableListBodyElement
    if (isOrder) {
      listBodyElement = tableListBodyElement
      if (!opened) {
        listBodyElement = null
      }
    }

    let newState = {}
    let _pedidosSelecionados = [...pedidosSelecionados]

    let arrowPosition = '0px'
    if (listBodyElement) {
      const arrowOffset = (listBodyElement.offsetTop - this.headerHeight) + (this.arrowElement.clientHeight * index)
      arrowPosition = `${arrowOffset}px`
    }

    if (isOrder) {
      if (opened) {
        newState = {
          idPedido: item?.Id,
          isPedidoSelecionado,
          isPedidoVolume: isOrderBulk,
        }

        if (!pedidosSelecionados.includes(newState.idPedido)) {
          _pedidosSelecionados.push(newState.idPedido)
        }
      } else if (item) {
        _pedidosSelecionados = pedidosSelecionados.filter(idItemSelecionado => idItemSelecionado !== item.Id)
      }
    }

    this.setState({
      arrowPosition: arrowPosition,
      maxHeightTimeLine: `${heightCardDetail - heightChart}px`,
      itemExpanded: _itemExpanded,
      pedidosSelecionados: _pedidosSelecionados,
      lastOrderOpen: {
        index: itemIndex,
        tableListBodyElement: listBodyElement,
      },
      ...newState,
    })
  }

  async salvarAnotacoes() {
    const estoqueDetalhe = this.state.estoqueDetalhe
    const visaoEstoqueInformacoes = {
      IdFamiliaRotuladaCentro: estoqueDetalhe.Id,
      Anotacoes: estoqueDetalhe.Anotacoes,
    }

    try {
      this.handleFetch();
      await saveOverviewStockInformation(visaoEstoqueInformacoes);
      this.showFeedback(intl.get('stocks.timeline.feedback.notesSavedSuccessfully'));
    } catch (err) {
      this.showFeedback(formatErrorMessage(err))
    } finally {
      this.handleFetch(false);
    }
  }

  verifyPedidosTransf = (estoqueDetalhe) => {
    if (estoqueDetalhe && estoqueDetalhe.ListaPedidos) {
      return estoqueDetalhe.ListaPedidos.filter(p => p.TipoPedido == TipoPedidoTransferencia)
    }

    return []
  }

  handleShowModalEstouroDaGrade = () => {
    this.setState(prevState => ({
      showModalAlertaEstouroDaGrade: !prevState.showModalAlertaEstouroDaGrade
    }))
  }

  checkShelflife = (items) => {
    this.setState({
      itemsShelfLifeCheckedData: items
    })
  }

  async acceptSuggestionsShelfLife(itemsShelfLifeCheckedData) {
    this.handleFetch();
    this.setState({ itemsShelfLifeCheckedData });

    try {
      await createSuggestionByShelfLife(this.state.idFamiliaRotuladaCentro, itemsShelfLifeCheckedData);
      this.showFeedback(intl.get('stocks.timeline.feedback.successfullyCreatedSuggestions'))
      this.loadCompletePage();
    } catch (err) {
      console.log(err)
    } finally {
      this.handleFetch(false);
    }
  }

  getSecondChartInfo = (filters) => {
    this.setState({
      secondChartInfo: filters
    })
  }

  renderChart = (estoqueDetalhe, informacoesDoEstoque) => (
    estoqueDetalhe &&
    <>
      <ChartStocks
        idFamiliaRotuladaCentro={this.state.idFamiliaRotuladaCentro}
        lastUpdateDate={informacoesDoEstoque.UltimaAtualizacao}
        hideLastUpdate
        ref={this.chartStockRef}
        hideLoading={this.state.requestCount > 0}
        sendInfosToSecondChart={this.getSecondChartInfo}
        updateChart={this.state.updateGraphic}
      />
      <ExternalWarehouseBreweryCharts
        wasUpdateGraph={this.state.wasUpdateGraph}
        unitType={estoqueDetalhe.TipoUnidade}
        labeledFamilyId={estoqueDetalhe.IdFamiliaRotulada}
        businessUnitId={estoqueDetalhe.IdUnidadeNegocio}
        filters={this.state.secondChartInfo}
      />
    </>
  )

  render() {
    const {
      estoqueDetalhe,
      showFeedback,
      messageFeedback,
      maxHeightTimeLine,
      requestCount,
      arrowPosition,
      idFamiliaRotuladaCentro,
      idPedido,
      isPedidoVolume,
      isPoliticaEstoqueVariavel,
      itemExpanded,
      existeTransferenciaShelflife,
      showModalAlertaEstouroDaGrade,
      showModalPedidoEmOver,
      gradeData,
      showCompositionOrders,
      informacoesDoEstoque,
      isNewFlow,
      linhaProducaoCentro,
      documentTypes,
      doors
    } = this.state

    if (SessionUser.get().TipoUsuario === TipoUsuario.Fornecedor) {
      return <NoAccessSupplierPage />
    }

    const pedidosTransf = this.verifyPedidosTransf(estoqueDetalhe)
    return (
      <div className='stocks-container-detail'>
        <Loading isLoading={requestCount > 0} />
        <Header title={intl.get('commons.stocks')} />
        <div className="stocks-container">
          <div className="stocks-left">
            <div className="card-detail" ref={ref => this.cardDetailElement = ref}>
              {estoqueDetalhe &&
                <Card
                  type={'StocksDetail'}
                  title={`${estoqueDetalhe.Status}`}
                  subtitle={`${estoqueDetalhe.Centro} - ${estoqueDetalhe.FamiliaRotulada}`}
                  status={estoqueDetalhe.Etapa}
                  criticidade={estoqueDetalhe.Criticidade}
                  customOptionsHeader={this.renderHeaderCustomOptions(estoqueDetalhe.Programado, existeTransferenciaShelflife)}
                  cursor='auto'
                >
                  <div className="card-detail-content">
                    <div className="body-card-stock">
                      <div className="data-card">
                        <span className="label-card">{intl.get("stocks.stockDetail.unit")}</span>
                        <span>{estoqueDetalhe.UnidadeNegocio}</span>
                      </div>
                      <div className="data-card">
                        <span className="label-card">{intl.get("stocks.stockDetail.peMIN")}</span>
                        <span>{formatNumber(estoqueDetalhe.PoliticaEstoqueMin)} {estoqueDetalhe.UnidadeMedida}</span>
                      </div>
                      <div className="data-card">
                        <span className="label-card">{intl.get("stocks.stockDetail.peMAX")}</span>
                        <span>{formatNumber(estoqueDetalhe.PoliticaEstoqueMax)} {estoqueDetalhe.UnidadeMedida}</span>
                      </div>
                      <div className="data-card">
                        <span className="label-card">{intl.get("stocks.stockDetail.nextDelivery")}</span>
                        {
                          estoqueDetalhe.DataProximaEntrega ?
                            <span>
                              {moment(estoqueDetalhe.DataProximaEntrega, "DD/MM").format("[DM]")}
                              {" " + "-" + " "}
                              {formatNumber(estoqueDetalhe.QuantidadeProximaEntrega)}{estoqueDetalhe.UnidadeMedida}
                            </span>
                            : '-'
                        }
                      </div>
                    </div>
                    <div className="body-card-stock">
                      <div className="data-card">
                        <span className="label-card">{intl.get("requestOrders.dateOut")}</span>
                        {estoqueDetalhe.DataOut ?
                          <span>{moment(estoqueDetalhe.DataOut).format('L') || 'N/A'}</span> : 'N/A'
                        }
                      </div>
                      <div className="data-card">
                        <span className="label-card">{intl.get("commons.sufficiency")}</span>
                        {estoqueDetalhe.Suficiencia ?
                          <span>{moment(estoqueDetalhe.Suficiencia).format('L') || '-'}</span> : '-'
                        }
                      </div>
                      <div className="data-card">
                        <span className="label-card">{intl.get("commons.availableStock")}</span>
                        <StatusStock
                          priority={estoqueDetalhe.PrioridadeEstoque}
                          label={`${formatNumber(estoqueDetalhe.EstoqueValor)} ${estoqueDetalhe.EstoqueUnidadeMedida}`}
                        />
                      </div>
                      <div className="data-card" id="div-blocked-stock">
                        <span className="label-card">{intl.get("management.stockBlocked")}</span>
                        {estoqueDetalhe.EstoqueBloqueado ?
                          <StatusStock
                            priority={16}
                            label={`${formatNumber(estoqueDetalhe.EstoqueBloqueado)} ${estoqueDetalhe.EstoqueUnidadeMedida}`}
                          />
                          : '-'
                        }
                      </div>
                    </div>
                    <EstoqueCompartilhado
                      title={intl.get('menu.subMenuGeneral.supplierStock')}
                      data={this.state.estoqueCompartilhado}
                      show={true}
                    />
                    <EstoqueCompartilhado
                      title={intl.get('stocks.stockDetail.externalWarehouses')}
                      data={this.state.externalWarehouses}
                      show={true}
                    />
                    <EstoqueATG
                      show={this.showEstoqueATG()}
                      idFamiliaRotuladaCentro={this.state.idFamiliaRotuladaCentro}
                    />
                    <ProductionPlan
                      show={validatesArrayHasValue(this.state.productionPlan)}
                      data={this.state.productionPlan}
                    />
                    <Feature validation={showInboundTransfer()}>
                      <div className="body-card-stock">
                        <div className="data-card">
                          <span className="label-card">{intl.get("commons.inboundTransfer")}</span>
                          <StatusStock
                            label={`${formatNumber(estoqueDetalhe?.TransferStock)} ${estoqueDetalhe?.EstoqueUnidadeMedida}`}
                          />
                        </div>
                      </div>
                    </Feature>
                    <div className="body-card-stock">
                      <div className="data-card">
                        <span className="label-card">{intl.get("commons.activeMaterial")}</span>
                        <span>{estoqueDetalhe.MaterialAtivo}</span>
                      </div>
                    </div>
                    {estoqueDetalhe.ReplacementMaterial &&
                      <div className="body-card-stock">
                        <div className="data-card">
                          <span className="label-card">{intl.get("commons.replacementMaterial")}</span>
                          <span>{estoqueDetalhe.ReplacementMaterial}</span>
                        </div>
                        <div className="data-card same-block">
                          <span className="label-card">{intl.get("commons.descontinuationDate")}</span>
                          {estoqueDetalhe.DescontinuationDate
                            ? <span>{moment(estoqueDetalhe.DescontinuationDate).format('L')}</span>
                            : 'N/A'
                          }
                        </div>
                      </div>
                    }
                  </div>
                  {existeTransferenciaShelflife &&
                    <div className="suggestion-list">
                      <h3><span style={{ color: "#ff5e53" }}> {intl.get(`stocksdetail.warningShelfLife`)}</span></h3>
                      <Suggestions
                        isShelfLife
                        data={estoqueDetalhe.ListaLotesShelflife}
                        itemExpanded={itemExpanded}
                        itemsExpanded={this.state.itemsShelfLifeExpanded}
                        itemsChecked={this.state.itemsShelfLifeChecked}
                        expandItem={this.expandShelfLife}
                        checkAllItems={this.handleCheckShelfLifeItem}
                        checkItem={this.handleCheckShelfLifeItem}
                        idFamiliaRotuladaCentro={parseInt(idFamiliaRotuladaCentro)}
                        salvarSugestao={this.salvarSugestao}
                        changeHoraEntrega={this.changeHoraEntrega}
                        changeValueSugestao={this.changeValueSugestao}
                        shelflifeExpandedList={this.shelflifeExpandedList}
                        handleFeedback={this.showFeedback}
                        itemHighlighted={false}
                        acceptSuggestionsShelfLife={this.acceptSuggestionsShelfLife.bind(this)}
                        checkShelflifeItem={this.checkShelflife}
                      />
                    </div>
                  }
                  <div className="suggestion-list">
                    <h3 style={{ marginBottom: '10px' }}> {intl.get('modalShelfLife.suggestions')} </h3>
                    <Suggestions
                      centro={this.state.estoqueDetalhe.Centro}
                      idUnidadeNegocio={this.state.estoqueDetalhe.IdUnidadeNegocio}
                      data={mapperSuggestionsList(estoqueDetalhe, documentTypes, doors)}
                      itemExpanded={itemExpanded}
                      itemHighlighted={this.state.tipo == 'S' ? this.state.idItemDestacado : ''}
                      formValid={this.state.formValid}
                      itemsExpanded={this.state.itemsExpanded}
                      itemsChecked={this.state.itemsChecked}
                      expandItem={this.expandSuggestion}
                      checkAllItems={this.handlecheckAllItems}
                      checkItem={this.handleCheckItem}
                      createSuggestionManual={estoqueDetalhe.ExternalSuggestions ? this.createExternalSuggestion.bind(this) : this.createSuggestionManual.bind(this)}
                      verificarInconsistenciasDaSugestao={this.verificarInconsistenciasDaSugestao}
                      idFamiliaRotuladaCentro={parseInt(idFamiliaRotuladaCentro)}
                      salvarSugestao={this.salvarSugestao.bind(this)}
                      excluirSugestao={this.excluirSugestao.bind(this)}
                      changeValueSugestao={this.changeValueSugestao}
                      obterListaJustificativasSugestaoManual={this.obterListaJustificativasSugestaoManual.bind(this)}
                      listaJustificativasSugestaoManual={this.state.listaJustificativasSugestaoManual}
                      changeValueDeadline={this.changeValueDeadline}
                      suggestionsExpandedList={this.suggestionsExpandedList}
                      handleFeedback={this.showFeedback}
                      changeHoraEntrega={this.changeHoraEntrega}
                      linhaProducaoCentro={linhaProducaoCentro}
                      documentTypes={documentTypes}
                      doors={doors}
                      isHourlyPlan={estoqueDetalhe.IsHourlyPlan}
                      isExternalSuggestion={estoqueDetalhe.ExternalSuggestions}
                    />
                  </div>
                  <div></div>
                  {estoqueDetalhe.Periodicidade == TipoPedidoTransferencia &&
                    <div className="orders-list">
                      <h3>{intl.get('stocks.pendentVolumn')}</h3>
                      <Suggestions
                        isOrderBulk
                        data={estoqueDetalhe.ListaPedidosVolume}
                        itemHighlighted={this.state.tipo == 'V' ? this.state.idItemDestacado : ''}
                        itemExpanded={itemExpanded}
                        materialAtivo={estoqueDetalhe.MaterialAtivo}
                        itemsExpanded={this.state.itemsExpanded}
                        expandItem={this.calcTimelineMaxHeight}
                        cancelarSaldoPedidoVolume={this.cancelarSaldoPedidoVolume.bind(this)}
                        showCancelConfirm={this.showCancelConfirm}
                        showFormOrder={this.showFormOrder}
                      />
                    </div>
                  }
                  <div className="orders-list">
                    <h3>{intl.get('menu.menuGeneral.orders')}</h3>
                    <Suggestions
                      isOrder
                      centro={this.state.estoqueDetalhe.Centro}
                      data={mapperOrdersList(estoqueDetalhe)}
                      itemHighlighted={this.state.tipo == 'P' ? this.state.idItemDestacado : ''}
                      itemExpanded={itemExpanded}
                      materialAtivo={estoqueDetalhe.MaterialAtivo}
                      itemsExpanded={this.state.itemsExpanded}
                      expandItem={this.calcTimelineMaxHeight}
                      showCancelConfirm={this.showCancelConfirm}
                      showFormOrder={this.showFormOrder}
                      changeValueOrder={this.changeValueOrder}
                      saveOrder={this.saveOrder.bind(this)}
                      usuarioComPermissaoParaCancelarPedidos={this.usuarioComPermissaoParaCancelarPedidos}
                      isNewFlow={isNewFlow}
                      linhaProducaoCentro={linhaProducaoCentro}
                      isHourlyPlan={estoqueDetalhe.IsHourlyPlan}
                    />
                  </div>
                  {estoqueDetalhe.ListaTransferenciaSaida && estoqueDetalhe.ListaTransferenciaSaida.length > 0 &&
                    <div className="orders-list transfer-out-list">
                      <h3>{intl.get('stocks.orderTransferOut')}</h3>
                      <Suggestions
                        isOrder
                        isTransfer
                        data={estoqueDetalhe.ListaTransferenciaSaida}
                        itemHighlighted={this.state.tipo == 'P' ? this.state.idItemDestacado : ''}
                        itemExpanded={itemExpanded}
                        materialAtivo={estoqueDetalhe.MaterialAtivo}
                        itemsExpanded={this.state.itemsExpanded}
                        expandItem={this.calcTimelineMaxHeight}
                        showCancelConfirm={this.showCancelConfirm}
                        showFormOrder={this.showFormOrder}
                        usuarioComPermissaoParaCancelarPedidos={this.usuarioComPermissaoParaCancelarPedidos}
                        isNewFlow={isNewFlow}
                      />
                    </div>
                  }
                  <div className="card-detail-content">
                    <div className="body-card-stock">
                      <div className="data-card">
                        <Row bottom="xs row-filter">
                          <Col xs={12}>
                            <Textarea
                              style={getTextAreaStyle()}
                              label={intl.get('stocks.stockDetail.annotations')}
                              name="Anotacoes"
                              value={estoqueDetalhe.Anotacoes || ''}
                              onChange={this.changeValue}
                            />
                          </Col>
                        </Row>
                        <div className="table-list-footer">
                          <Button
                            width='200px'
                            value={intl.get('stocks.stockDetail.saveAnnotations')}
                            type='secondary'
                            onClick={this.salvarAnotacoes.bind(this)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              }
            </div>
          </div>

          <div className="stocks-right">
            <div className="stocks-right-container">
              <span
                className="arrow"
                ref={ref => this.arrowElement = ref}
                style={{ top: arrowPosition }}
              />
              <div
                ref={ref => this.chartElement = ref}
              >
                {this.renderChart(estoqueDetalhe, isPoliticaEstoqueVariavel, pedidosTransf, informacoesDoEstoque)}
              </div>
              {
                idPedido &&
                <Timeline
                  searchTimeLine={this.state.searchTimeLine}
                  searchTimeLineFinished={this.searchTimeLineFinished}
                  idPedido={idPedido}
                  isPedidoVolume={isPedidoVolume}
                  maxHeight={maxHeightTimeLine}
                  handleFeedback={this.showFeedback}
                  refreshCardDetail={() => this.loadCompletePage()}
                />
              }
            </div>
          </div>
        </div>

        {this.state.showCancelConfirm &&
          <DangerDialog
            show={this.state.showCancelConfirm}
            title={intl.get('stocks.timeline.feedback.confirmCancelOrder')}
            labelButton={intl.get('stocks.suggestionsListItem.cancelOrder')}
            labelButtonClose={intl.get('stocks.suggestionsListItem.close')}
            handleConfirm={this.cancelarPedido.bind(this)}
            handleClose={this.closeCancelConfirm}
            justificationMessages={this.state.justificationMessages}
            statusPedidoEmCancelamento={this.state.statusPedidoEmCancelamento}
            handleConfirmRequestOrderCancellation={this.handleConfirmRequestOrderCancellation.bind(this)}
            isLoading={requestCount > 0}
          />
        }
        {showCompositionOrders &&
          <CompositionOrders
            pedido={this.state.pedido}
            onClose={this.closeCompositionOrders}
            handleConfirm={this.cancelarComposicao.bind(this)}
            message={intl.get('stocks.timeline.feedback.messageCompositionOrders')}
          />
        }

        {this.state.openForm &&
          <ManualSuggestionForm
            open
            editMode={false}
            handleClose={this.closeManualSuggestionForm}
            idFamiliaRotuladaCentro={parseInt(idFamiliaRotuladaCentro)}
          />
        }

        {this.state.showFormOrder &&
          <InformAdvanceDelayOrder
            open
            pedido={this.state.pedido}
            onClose={this.closeFormOrder}
            onSave={this.salvarNovasDatasColetaEntrega}
          />
        }

        {this.state.visualizarGestaoIdade &&
          <ModalShelfLife
            open={this.state.visualizarGestaoIdade}
            idFamiliaRotuladaCentro={parseInt(idFamiliaRotuladaCentro)}
            idUnidadeNegocio={estoqueDetalhe && estoqueDetalhe.IdUnidadeNegocio}
            subtitle={estoqueDetalhe ? `${estoqueDetalhe.Centro} - ${estoqueDetalhe.FamiliaRotulada}` : ''}
            handleClose={this.visualizarGestaoIdade}
            handleCloseReload={this.handleCloseReload}
          />
        }

        {showModalAlertaEstouroDaGrade &&
          <ModalGradeExcedida
            gradeData={gradeData}
            showModalAlertaEstouroDaGrade={showModalAlertaEstouroDaGrade}
            handleShowModalEstouroDaGrade={this.handleShowModalEstouroDaGrade}
            estouroDeGradeAceito={this.estouroDeGradeAceito}
          />
        }

        {showModalPedidoEmOver &&
          <ModalPedidoEmOver
            showModalPedidoEmOver={showModalPedidoEmOver}
            listaJustificativasPedidoEmOver={this.state.listaJustificativasPedidoEmOver ? this.state.listaJustificativasPedidoEmOver : ''}
            handleShowModalPedidoEmOver={this.handleShowModalPedidoEmOver}
            pedidoEmOverAceito={this.pedidoEmOverAceito}
          />
        }

        <SnackBar
          message={messageFeedback}
          open={showFeedback}
          autoHideDuration={3000}
          onRequestClose={this.closeFeedback}
        />
      </div>
    )
  }
}

export default StocksDetail
