import Fetch from "utils/fetch";

export const getMaterialSupplierSettings = (idFamiliaRotuladaCentro) => {
  return Fetch.get(`masterdata/material-supplier-settings/stock-detail/${idFamiliaRotuladaCentro}`).then(({ data }) => data)
}

export const searchTransfers = (idFamiliaRotuladaCentro) => {
  return Fetch.get(`shelfLife/consultarExistenciaTransferencias/${idFamiliaRotuladaCentro}`).then(({ data }) => data)
}

export const searchChartData = (idFamiliaRotulada, model) => {
  return Fetch.post(`/visaoEstoque/consultaVisaoEstoqueCardDetalheGrafico/${idFamiliaRotulada}`, model).then(({ data }) => data)
}

export const searchVariableStockPolicy = (idFamiliaRotuladaCentro) => {
  return Fetch.get(`/visaoEstoque/obterPoliticaEstoqueVariavel/${idFamiliaRotuladaCentro}`).then(({ data }) => data);
}

export const searchCardDetail = (idFamiliaRotulada) => {
  return Fetch.get(`/visaoEstoque/consultaVisaoEstoqueCardDetalhe/${idFamiliaRotulada}`).then(({ data }) => data);
}

export const checkSuggestionInconsistencies = (idUser, idFamiliaRotuladaCentro, materials) => {
  return Fetch.post(`/sugestoes/verificarInconsistenciasDaSugestao/${idUser}/${idFamiliaRotuladaCentro}`, { Sugestoes: materials }).then(({ data }) => data);
}

export const searchJustificationsRequestOnOver = () => {
  return Fetch.get(`/sugestoes/listaJustificativasPedidoEmOver`).then(({ data }) => data);
}

export const acceptSuggestions = (idUser, idFamiliaRotuladaCentro, justificativasPedidoEmOverOption, sugestaoGeraEstoqueOverOuMaxmimaEstoque, suggestions) => {
  const body = {
    UserId: idUser,
    LabeledFamilyCenterId: idFamiliaRotuladaCentro,
    OrderInOverOrMaxStock: sugestaoGeraEstoqueOverOuMaxmimaEstoque,
    OrderInOverJustificationType: justificativasPedidoEmOverOption,
    Suggestions: suggestions
  }
  return Fetch.post('suggestions:accept', body);
}

export const acceptExternalSuggestions = (idUser, idFamiliaRotuladaCentro, justificativasPedidoEmOverOption, sugestaoGeraEstoqueOverOuMaxmimaEstoque, materials) => {
  const suggestion = {
    IdSuggestion: materials,
    UserId: idUser,
    LabeledFamilyCenterId: idFamiliaRotuladaCentro,
    JustificationTypeOverOrder: justificativasPedidoEmOverOption,
    OverOrderOrMaximumStock: sugestaoGeraEstoqueOverOuMaxmimaEstoque
  }
  return Fetch.post(`/suggestions/external-data:accept`, suggestion);
}

export const changeRequest = (alteracaoPedido) => {
  return Fetch.post(`/pedido/alterarPedidoVisaoEstoqueDetalhe`, alteracaoPedido).then(({ data }) => data);
}

export const saveSuggestion = (sugestao, isExternalSuggestion) => {
  const build = {
    model: sugestao,
    method: 'put',
    url: '/sugestoes/salvarSugestao'
  }

  if (isExternalSuggestion) {
    build.model = {
      Id: sugestao.Id,
      BusinessUnitId: sugestao.IdUnidadeNegocio,
      MaterialId: sugestao.IdMaterial,
      SupplierId: sugestao.IdFornecedor,
      PortOfLoadingId: sugestao.PortOfLoadingId,
      PortOfDischargeId: sugestao.PortOfDischargeId,
      Quantity: sugestao.QuantidadeSugeridaAte,
      DeliveryDate: sugestao.EntregaAte,
      DeliveryHour: sugestao.HoraEntrega,
      PickUpDate: sugestao.DataColeta,
      Priority: sugestao.PrioridadeXadrez,
      Incoterm: sugestao.ResponsavelEntrega,
      Notes: sugestao.Observacao,
      TaxCode: sugestao.CodigoImposto,
      BuyerGroup: sugestao.GrupoCompradores,
      PurchasingOrganization: sugestao.OrganizacaoCompras,
      ManualJustification: sugestao.TipoJustificativaSugestaoManual,
      DocumentType: sugestao.DocumentType,
      RequirementCode: sugestao.RequirementCode
    }
    build.url = 'suggestions/external-data/manual'
    build.method = sugestao.Id ? 'put' : 'post'
  }

  return Fetch[build.method](build.url, build.model)
}

export const deleteSuggestion = (idFamiliaRotuladaCentro, sugestoes, isExternalSuggestion) => {
  const build = {
    url: isExternalSuggestion ? `suggestions/external-data/manual:delete`
      : `/sugestoes/excluirSugestao/${idFamiliaRotuladaCentro}`,

    body: isExternalSuggestion ? { Id: sugestoes } : { Sugestoes: sugestoes }
  }

  return Fetch.post(build.url, build.body)
}

export const cancelBalance = (idItemVolume, idFamiliaRotuladaCentro, userId) => {
  return Fetch.post(`/pedidoVolume/cancelarSaldo/${idItemVolume}/${idFamiliaRotuladaCentro}/${userId}`);
}

export const newManualSuggestion = (idFamiliaRotuladaCentro) => {
  return Fetch.get(`/sugestoes/criarNovaSugestaoManual/${idFamiliaRotuladaCentro}`).then(({ data }) => data);
}

export const cancelOrder = (idFamiliaRotuladaCentro, cancelamento) => {
  return Fetch.put(`/pedido/cancelar/${idFamiliaRotuladaCentro}`, cancelamento);
}

export const applyProgrammingInStock = (idFamiliaRotuladaCentro) => {
  return Fetch.post(`/visaoEstoque/aplicarProgramacaoNoEstoque/${idFamiliaRotuladaCentro}`);
}

export const saveOverviewStockInformation = async (visaoEstoqueInformacoes) => {
  return Fetch.post(`/visaoEstoque/salvarVisaoEstoqueInformacoes/`, visaoEstoqueInformacoes).then(() => Promise.resolve());
}

export const createSuggestionByShelfLife = async (idFamiliaRotuladaCentro, itemsShelfLifeCheckedData) => {
  return Fetch.post(`shelfLife/criarSugestaoViaShelfLife/${idFamiliaRotuladaCentro}`, itemsShelfLifeCheckedData).then(() => Promise.resolve());
}

export const composicaoCargaCancel = (loadCompositionId, cancelamento) => {
  return Fetch.post(`/load-compositions/${loadCompositionId}:cancel`, cancelamento);
}

export const lastUpdateOfStock = (idFamiliaRotuladaCentro) => {
  return Fetch.get(`/visaoEstoque/obterUltimaAtualizacao/${idFamiliaRotuladaCentro}`).then(({ data }) => data);
}

export const fetchEstoqueCompartilhado = (labeledFamilyCenterId) => {
  return Fetch.get(`/shared-stocks:by-labeled-family-center?labeledFamilyCenterId=${labeledFamilyCenterId}`).then(({ data }) => data);
}

export const fetchExternalWarehouses = (labeledFamilyCenterId) => {
  return Fetch.get(`/business-units:external-warehouses-stocks?labeledFamilyCenterId=${labeledFamilyCenterId}`).then(({ data }) => data);
}

export const requestOrderCancellation = (requestOrderCancellationDto) => {
  return Fetch.put(`/pedido/requestOrderCancellation`, requestOrderCancellationDto).then(({ data }) => data);
}

export const confirmCancellationAwaitingCheck = (idPedido) => {
  return Fetch.put(`/pedido/ConfirmCancellationAwaitingCheck/${idPedido}`).then(({ data }) => data);
}

export const getAllEstoqueATG = (idFamiliaRotuladaCentro) => (
  Fetch.get(`/estoqueAtivoGiro/obterEstoqueMaterialAtgPorFamiliaRotuladaCentro/${idFamiliaRotuladaCentro}`).then(({ data }) => data)
)

export const getAllLinkedBreweryOnExternalWarehouse = (query) => {
  return Fetch.get(`business-units/${query.businessUnitId}/breweries?labeledFamilyId=${query.labeledFamilyId}`).then(({ data }) => data)
}

export const getAllLinkedExternalWarehouseOnBrewery = (query) => 
  Fetch.get(`business-units/${query.businessUnitId}/external-warehouses?labeledFamilyId=${query.labeledFamilyId}`).then(({ data }) => data)

export const searchPorts = () => 
  Fetch.get('international-ports').then(({ data }) => data);